import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const PatientName = styled.p`
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
`;

export const PatientPrimaryDiagnosis = styled.p`
    color: ${props => props.theme.shades.grey};
`;

export const ViewMore = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PatientDetailsButton = styled(Buttons.LinkButton)`
    margin: 7px 0px;
    padding: 1px;
    font-weight: normal;
    text-decoration: underline;
`;
