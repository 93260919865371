import React, { useEffect, useState } from 'react';
import * as SParent from '../../index.styles';
import * as S from './index.styles';
import { IUser } from '../../../../../../types/models';
import { useOutletContext } from 'react-router';

function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function inchesToFeetInches(inches: number) {
    const feet = Math.floor(inches / 12);
    const remainingInches = inches % 12;
    return { feet, inches: remainingInches };
}

const HealthInfo = () => {
    const { patient } = useOutletContext<{ patient: IUser }>();
    const { feet, inches } = inchesToFeetInches(Number(patient.height));

    const differenceInYears = new Date().getFullYear() - new Date(patient.dateOfBirth).getFullYear();

    return (
        <SParent.PatientInfoRow>
            <S.Table>
                <tr>
                    <td colSpan={2}>Condition Information</td>
                </tr>
                <tr>
                    <td colSpan={2}><strong>{patient.medicalCondition}</strong></td>
                </tr>
            </S.Table>
            <S.Table>
                <tr>
                    <td colSpan={2}>Physical Information</td>
                </tr>
                <tr>
                    <td style={{ verticalAlign: 'top', width: '50%' }}>
                        <table>
                            <tr>
                                <td>Age</td>
                                <td><strong>{differenceInYears}</strong> ({patient.dateOfBirth})</td>
                            </tr>
                            <tr>
                                <td>Sex</td>
                                <td><strong>{capitalizeFirstLetter(patient.sexAssignedAtBirth)}</strong></td>
                            </tr>
                        </table>
                    </td>
                    <td style={{ verticalAlign: 'top', width: '50%' }}>
                        <table>
                            <tr>
                                <td>BMI</td>
                                <td><strong>{}</strong></td>
                            </tr>
                            <tr>
                                <td>Height</td>
                                <td><strong>{feet} ft, {inches} in</strong></td>
                            </tr>
                            <tr>
                                <td>Weight</td>
                                <td><strong>{patient.weight} lbs</strong></td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </S.Table>
        </SParent.PatientInfoRow>
    );
};

export default HealthInfo;
