import styled from 'styled-components';
import { PatientStatus } from '../../types/models';

export const PageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
`;

export const ProgramStatusIndicator = styled.span<{status: 'success' | 'danger' | 'info', fullWidth?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.fullWidth ? '100%' : '200px'}};
  height: 30px;
  border-radius: 10px;
  color: white;
  background-color: ${props => {
        switch (props.status) {
            case 'success':
                return props.theme.success.dark;
            case 'danger':
                return props.theme.danger.dark;
            case 'info':
                return props.theme.primary.dark;
            default:
                return props.theme.primary.dark;
        }
    }};
`;

export const PatientStatusTag = styled.span<{status: PatientStatus}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 18px;
  border-radius: 9px;
  color: white;
  font-size: 14px;
  background-color: ${props => {
        switch (props.status) {
            case 'VPR':
                return props.theme.statusTag.beige;
            case 'PCM':
                return props.theme.statusTag.blue;
            case 'MNT':
                return props.theme.statusTag.teal;
            default:
                return props.theme.statusTag.blue;
        }
    }};
`;

export const Error = styled.h4`
  color: ${props => props.theme.danger.medium};
  font-weight: 400;
`;
