import React from 'react';
import * as S from './index.styles';

type Props = {
    expanded: boolean;
    onClick: () => void;
}

const CollapseExpandButton = ({ expanded, onClick }: Props) => {
    return (
        <S.ToggleButton
          onClick={onClick}
          buttonType="tertiary"
        >
            {expanded ? '- Collapse' : '+ Expand' }
        </S.ToggleButton>
    );
};

export default CollapseExpandButton;
