import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faCalendarCheck, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPatientListUser, IPatientError, UserRoles } from '../../types/models';
import PatientDataTableCells from './components/PatientDataTableCells';
import * as S from './index.styles';
import ErrorDataTableCells from './components/ErrorDataTableCells';

export const getPatientColumnsForRole = (role: UserRoles | null) => {
    let columns = [
        {
            name: 'Status',
            grow: 0.5,
            cell: PatientDataTableCells.StatusCell,
        },
        {
            name: 'Patient',
            grow: 2,
            cell: PatientDataTableCells.PatientCell
        },

    ];
    if (role === UserRoles.PHYSICAL_THERAPIST) {
        columns = [
            ...columns,
            {
                name: 'Plan of Care',
                grow: 1.5,
                cell: (row: IPatientListUser) => {
                    return (
                        <div>
                            <p>Start: {row.planOfCareStartDate}</p>
                            <p>End: {row.planOfCareEndDate}</p>
                        </div>
                    );
                }
            }
        ];
    }
    if (role === UserRoles.PHYSICAL_THERAPIST || role === UserRoles.RESPIRATORY_THERAPIST || role === UserRoles.NURSE_PRACTITIONER) {
        columns = [
            ...columns,
            {
                name: 'Appointments',
                grow: 1.5,
                cell: (row: IPatientListUser) => (
                    <div>
                        <p>
                            <FontAwesomeIcon icon={faCalendar as IconProp} size="xs" />
                            {row.nextScheduledAppointmentDate}
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faCalendarCheck as IconProp} size="xs" />
                            {row.lastAttendedAppointmentDate}
                        </p>
                    </div>
                )
            },
            {
                name: 'Alerts',
                grow: 1.5,
                cell: (row: IPatientListUser) => (
                    <div>
                        <p>{row.unseenExerciseCommentCount}</p>
                        <p>{row.unseenExerciseWarningsCount}</p>
                        <p>{row.taskCount}</p>
                    </div>
                )
            },
            {
                name: 'Activity',
                grow: 1.5,
                cell: (row: IPatientListUser) => {
                    return role === UserRoles.PHYSICAL_THERAPIST
                        ? (
                            <div>
                                <p>RMT: {row.RMTActivity7Days}</p>
                                <p>Exercise: {row.ExerciseActivity7Days}</p>
                            </div>
                        )
                        : (
                            <div>
                                <p>Education: {row.educationCompletedPercentage}</p>
                                <p>Education 30 days: {row.educationActivity30Days}</p>
                            </div>
                        );
                }
            },
            {
                name: role === UserRoles.PHYSICAL_THERAPIST ? 'RTM' : 'PCM',
                grow: 1,
                cell: (row: IPatientListUser) => (
                    <div>
                        {role === UserRoles.PHYSICAL_THERAPIST ? (
                            <>
                                <p>{row.rtmUnits}/4 RTM Units</p>
                                <p>{row.rmtMinutesUntilNextUnit} RTM min needed</p>
                            </>
                        ) : (
                            <>
                                <p>{row.pcmUnits}/3 PCM Units</p>
                                <p>{row.pcmMinutesUntilNextUnit} PCM min needed</p>
                            </>
                        )}

                    </div>
                )
            }
        ];
    } else {
        columns = [
            ...columns,
            {
                name: 'Billing',
                grow: 1,
                cell: (row: IPatientListUser) => (
                    <div>
                        <p>{ row.rtmUnits } RTM Units</p>
                        <p>{row.rmtMinutesUntilNextUnit } RTM min needed</p>
                        <p>{ row.pcmUnits } PCM Units</p>
                        <p>{row.pcmMinutesUntilNextUnit } PCM min needed</p>
                    </div>
                )
            },
        ];
    }

    return [
        ...columns,
        {
            name: 'View More',
            grow: 1,
            cell: PatientDataTableCells.ViewMoreCell
        }
    ];
};

export const errorColumns = (totalErrors: number, totalPatientsWithErrors: number) => ([
    {
        name: 'Status',
        grow: 0.5,
        center: true,
        cell: ErrorDataTableCells.StatusCell
    },
    {
        name: `Patient ${totalPatientsWithErrors}}`,
        grow: 3,
        cell: ErrorDataTableCells.PatientCell
    },
    {
        name: `Issues ${totalErrors}`,
        grow: 4,
        cell: (row: IPatientError) => (
            <div>
                {row.errors.map((error, index) => (
                    <p>
                        <FontAwesomeIcon icon={faFileLines as IconProp} />
                        {' '}{error.message}
                    </p>
                ))}
            </div>
        )

    },
    {
        name: 'View More',
        grow: 1,
        cell: ErrorDataTableCells.ViewMoreCell
    },
]);
