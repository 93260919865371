import styled from 'styled-components';

export const LinkImg = styled.img`
    width: 100px;
    height: 20px;
    object-fit: contain;
    object-position: left;
`;

export const LinkRow = styled.a`
    display: flex;
    gap: 3px;
    align-items: center;
`;

export const LinkArrow = styled.img`
    width: 10px;
    height: 10px;
    object-fit: contain;
`;
