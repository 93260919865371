import React, { useEffect, useState } from 'react';
import * as SParent from '../../index.styles';
import * as S from './index.styles';
import CollapseExpandButton from '../CollapseExpandButton';
import CourseProgress from './components/CourseProgressCard';
import ActionPlan from './components/ActionPlan';

type Props = {
    expandedState: boolean;
}

const CareManagementAndCourseRow = ({ expandedState } : Props) => {
    const [expanded, setExpanded] = useState(expandedState);

    return (
        <>
            <SParent.HeaderRow>
                <SParent.Header>Care Management</SParent.Header>
                <CollapseExpandButton
                  expanded={expanded}
                  onClick={() => {
                    setExpanded(prev => !prev);
                }}
                />
            </SParent.HeaderRow>
            {expanded && (
                <S.InfoRow>
                    <CourseProgress />
                    <ActionPlan />
                </S.InfoRow>
            )}
        </>
    );
};

export default CareManagementAndCourseRow;
