import React, { useEffect, useState } from 'react';
import * as SParent from '../../index.styles';
import * as S from './index.styles';
import { IUser, UserRoles, IRole } from '../../../../../../types/models';
import { useModal, constants } from '@apps/common-ui';
import PaymentAccessModal from './components/PaymentAccessModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import { count } from 'console';

function getProviderType(role: UserRoles | null): string {
    switch (role) {
        case UserRoles.PHYSICAL_THERAPIST:
            return 'PT';
        case UserRoles.RESPIRATORY_THERAPIST:
            return 'RT';
        case UserRoles.NURSE_PRACTITIONER:
            return 'NP'; // TODO: check if this is correct
        default:
            return '';
    }
}

const ErrorAndBillingRow = () => {
    const { currentPatient: patient } = useSelector((state: RootState) => state.coaching);
    const { userBillableRole: role } = useSelector((state: RootState) => state.session);
    const [access, setAccess] = useState(false);// TODO: udpate state
    const { isOpen, openModal, closeModal } = useModal();

    const handleAccess = (choice: boolean) => {
        if (choice && patient) {
            patient.groupCode = constants.USER_GROUP_CODES.healthieBilling;
        } else if (patient) {
            patient.groupCode = '';
        }
        // TODO: update patient
        setAccess(choice);
    };

    useEffect(() => {
        setAccess(patient?.groupCode?.includes(constants.USER_GROUP_CODES.healthieBilling) || false);
    }, [patient]);

    return (
        <>
            {isOpen && (
                <PaymentAccessModal onClose={closeModal} handleSelect={handleAccess} />
            )}
            <SParent.PatientInfoRow>
                <S.ErrorsContainer>
                    <S.Label type="errors">Errors to address</S.Label>
                    {patient?.errors?.length === 0 && (
                        <S.Item size="small">No Errors</S.Item>
                    )}
                    {patient?.errors?.map((error) => (
                        // TODO: add error component
                        <p>{error.message}</p>
                    ))}
                </S.ErrorsContainer>
                <S.InsuranceContainer>
                    <S.Label>Insurance & Billing</S.Label>
                    {role === UserRoles.PHYSICAL_THERAPIST && (
                        <S.RowItems>
                            <S.Item>Max PT Appointment Length</S.Item>
                            <S.Item size="small"><strong>{patient?.flatRateBilling ? 45 : 60} min</strong></S.Item>
                        </S.RowItems>
                    )}
                    <S.RowItems>
                        <S.Item>Access To Healthie Payment</S.Item>
                        <S.Item size="small">
                            {access
                                ? (
                                    <S.PencilButton
                                      buttonType="tertiary"
                                      onClick={openModal}
                                      color="green"
                                    >
                                        <strong>Yes</strong><img src="/pencil.svg" alt="pencil" />
                                    </S.PencilButton>
                                )
                                : (
                                    <S.PencilButton
                                      buttonType="tertiary"
                                      onClick={openModal}
                                      color="red"
                                    >
                                        <strong>No</strong><img src="/pencil.svg" alt="pencil" />
                                    </S.PencilButton>
                                )}
                        </S.Item>
                    </S.RowItems>
                </S.InsuranceContainer>
            </SParent.PatientInfoRow>
        </>
    );
};

export default ErrorAndBillingRow;
