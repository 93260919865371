import { Auth } from 'aws-amplify';
import { createSlice } from '@reduxjs/toolkit';
import { Routes } from '../../../api/Routes';
import { ICoach, IPatientListUser, IPatient, IPatientError, IUser, UserAssignment } from '../../../types/models';
import { AppDispatch } from '../../store';
import { TenantApi } from '../../../api/CoachingAPI';
import { setCurrent98976PeriodSummary } from '../billing';

export interface CoachingState {
    coaches: ICoach[] | [];
    patientListUsers: IPatientListUser[] | [];
    currentPatient: IPatient | null;
    patientErrors: IPatientError[];
}

const initialState = {
    coaches: [],
    currentPatient: null,
    patientListUsers: [],
    patientErrors: []
} as CoachingState;

const sessionSlice = createSlice({
    name: 'coaching',
    initialState,
    reducers: {
        setCoaches_: (state, { payload }: {payload: { coaches: ICoach[] }}) => {
            state.coaches = payload.coaches;
        },
        setPatientListUsers: (state, { payload }: {payload: { patientListUsers : IPatientListUser[] }}) => {
            state.patientListUsers = payload.patientListUsers;
        },
        setCurrentPatient: (state, { payload }: {payload: { patient: IPatient | null }}) => {
            state.currentPatient = payload.patient;
        },
        updatePatient: (state, { payload }: {payload: { patient: IPatient }}) => {
            state.currentPatient = payload.patient;
        }

    }
});

export const {
    setCoaches_,
    setPatientListUsers,
    setCurrentPatient,
    updatePatient
} = sessionSlice.actions;

export default sessionSlice.reducer;

type PatientsFetchParams = {
    sort: string;
    page: number;
    size: number;
    name?: string;
    coachId?: string;
    assigned?: boolean;
}

export const fetchPatients = ({ page, size, search, coachId, assigned }:
    { page: number, size: number, search?: string, coachId?: string, assigned?: boolean }) => async (dispatch: AppDispatch) => {
    const params: PatientsFetchParams = {
        sort: 'createdAt,desc',
        page,
        size
    };
    if (search) {
        params.name = search;
    }
    if (coachId) {
        params.coachId = coachId;
    }
    if (assigned !== undefined) {
        params.assigned = assigned;
    }
    let patients = await TenantApi.get(`/${Routes.coaches}/patients`, { params });
    if (!patients) {
        patients = {
            content: [],
            totalElements: 0
        };
    }
    dispatch(setPatientListUsers({ patientListUsers: patients.content }));
};

export const fetchCoaches = () => async (dispatch: AppDispatch) => {
    const coaches = await TenantApi.get(`/${Routes.coaches}/summary`);
    dispatch(setCoaches_({ coaches }));
};

export const setActivePatient = (patient: IPatient) => async (dispatch: AppDispatch) => {
    // TODO: Remove this once the backend is fixed
    patient.lastAttendedNPAppointmentDate = new Date().toISOString();
    patient.nextNPAppointmentDate = new Date().toISOString();
    patient.lastAttendedRTAppointmentDate = new Date().toISOString();
    patient.nextRTAppointmentDate = new Date().toISOString();
    patient.lastAttendedPTAppointmentDate = new Date().toISOString();
    patient.nextPTAppointmentDate = new Date().toISOString();
    patient.missedNPAppointmentCount = 0;
    patient.missedRTAppointmentCount = 0;
    patient.missedPTAppointmentCount = 0;
    dispatch(setCurrentPatient({ patient }));
};

export const clearCurrentPatient = () => async (dispatch: AppDispatch) => {
    dispatch(setCurrentPatient({ patient: null }));
};

export const patchUserAssignment = ({ patientId, userAssignment }: {patientId: string, userAssignment: UserAssignment}) => async (dispatch: AppDispatch) => {
    const updatedUser: IPatient = await TenantApi.patch(`/${Routes.coaches}/${Routes.users}/${patientId}/${Routes.userAssignment}`, userAssignment);
    dispatch(updatePatient({ patient: updatedUser }));
};
