import styled from 'styled-components';
import { Buttons } from '@apps/common-ui';

export const ErrorsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
    margin-right: 80px;
    gap: 10px;
`;

export const InsuranceContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    gap: 10px;
`;

export const Label = styled.p<{type?: 'errors' | 'insurance'}>`
    font-weight: bold;
    margin: 0 0 5px 0;
    color: ${(props) => {
        if (props.type === 'errors') return props.theme.shades.red;
        return 'black';
    }};
`;

export const RowItems = styled.div`
    display: flex;
    gap: 25px;
`;

export const Item = styled.div<{size?: 'small', color?: 'green' | 'red'}>`
    width: ${props => props.size === 'small' ? '80px' : '210px'};
    color: ${(props) => {
        if (props.color === 'green') return props.theme.text.success;
        if (props.color === 'red') return props.theme.text.error;
        return 'black';
    }};

    img {
        margin-left: 5px;
    }
`;

export const PencilButton = styled(Buttons.Button)`
    padding: 0;
    margin: 0;
    color: ${(props) => {
        if (props.color === 'green') return props.theme.text.success;
        if (props.color === 'red') return props.theme.text.error;
        return 'black';
    }};
`;
