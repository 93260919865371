import { Buttons } from '@apps/common-ui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { IUser, UserRoles, IRole } from '../../../../types/models';
import PatientPageHeader from '../PatientPageHeader';
import * as S from './index.styles';
import CareManagementAndCourseRow from './components/CareManagementAndCourseRow';
import ExercisePlanProgress from './components/ExercisePlanProgress';
import HealthInfo from './components/HealthInfoRow';
import ErrorAndBillingRow from './components/ErrorAndBillingRow';
import AppointmentDates from './components/AppointmentDates';
import LinkToHealthie from '../../../../components/LinkToHealthie';
import { formatDateForPatientPage } from '../../../../utils/dateUtils';

const PatientSummary = () => {
    const { coaches, currentPatient: patient } = useSelector((state: RootState) => state.coaching);
    const { userBillableRole: providerRole } = useSelector((state: RootState) => state.session);

    const careManagementExpandedState = providerRole === UserRoles.RESPIRATORY_THERAPIST
        || providerRole === UserRoles.NURSE_PRACTITIONER;
    const exerciseExpandedState = providerRole === UserRoles.PHYSICAL_THERAPIST;

    if (!patient) {
        return null;
    }

    const { healthieId } = patient;

    const heyMarketLink = 'https://app.heymarket.com/';
    const aircallLink = 'https://aircall.io/';

    const getPrimaryCoachName = (role: UserRoles) => {
        let coachId;
        if (role === UserRoles.PHYSICAL_THERAPIST) {
            coachId = patient.primaryPhysicalTherapist?.userId;
        } else if (role === UserRoles.RESPIRATORY_THERAPIST) {
            coachId = patient.primaryRespiratoryTherapist?.userId;
        } else if (role === UserRoles.NURSE_PRACTITIONER) {
            coachId = patient.primaryNursePractitioner?.userId;
        } else {
            return '';
        }
        return coaches.find(coach => coach.userId === coachId)?.name;
    };

    const getOtherCoachesNames = () => {
        const otherCoaches: string[] = [];
        patient.coaches.forEach((coach) => {
            const name = coaches.find((c) => c.userId === coach.userId)?.name || '';
            otherCoaches.push(name);
        });
        return otherCoaches;
    };

    return (
        <>
            <PatientPageHeader patient={patient}>
                <S.ExternalLinks>
                    <LinkToHealthie healthieId={healthieId} />
                    <S.LinkRow href={heyMarketLink} target="_blank" rel="noreferrer">
                        <S.LinkImg src="/Heymarket.svg" alt="Heymarket" />
                        <S.LinkArrow src="/LinkArrow.svg" alt="Link Arrow" />
                    </S.LinkRow>
                    <S.LinkRow href={aircallLink} target="_blank" rel="noreferrer">
                        <S.LinkImg src="/Aircall.svg" alt="Aircall" />
                        <S.LinkArrow src="/LinkArrow.svg" alt="Link Arrow" />
                    </S.LinkRow>
                </S.ExternalLinks>
            </PatientPageHeader>
            <ErrorAndBillingRow />
            <HealthInfo />
            <S.PatientInfoRow>
                <S.PatientInfoBlock>
                    <span>Primary PT</span>
                    <p>{getPrimaryCoachName(UserRoles.PHYSICAL_THERAPIST)}</p>
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>Primary RT</span>
                    <p>{getPrimaryCoachName(UserRoles.RESPIRATORY_THERAPIST)}</p>
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>Primary NP</span>
                    <p>{getPrimaryCoachName(UserRoles.NURSE_PRACTITIONER)}</p>
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>Other</span>
                    <p>{getOtherCoachesNames()}</p>
                </S.PatientInfoBlock>
            </S.PatientInfoRow>
            <S.PatientInfoRow>
                <S.PatientInfoBlock>
                    <span>PT Appointments</span>
                    <AppointmentDates
                      nextAppointmentDate={formatDateForPatientPage(new Date(patient.nextPTAppointmentDate))}
                      lastAppointmentDate={formatDateForPatientPage(new Date(patient.lastAttendedPTAppointmentDate))}
                      missedAppointments={patient.missedPTAppointmentCount}
                    />
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>RT Appointments</span>
                    <AppointmentDates
                      nextAppointmentDate={formatDateForPatientPage(new Date(patient.nextRTAppointmentDate))}
                      lastAppointmentDate={formatDateForPatientPage(new Date(patient.lastAttendedRTAppointmentDate))}
                      missedAppointments={patient.missedRTAppointmentCount}
                    />
                </S.PatientInfoBlock>
                <S.PatientInfoBlock>
                    <span>NP Appointments</span>
                    <AppointmentDates
                      nextAppointmentDate={formatDateForPatientPage(new Date(patient.nextNPAppointmentDate))}
                      lastAppointmentDate={formatDateForPatientPage(new Date(patient.lastAttendedNPAppointmentDate))}
                      missedAppointments={patient.missedNPAppointmentCount}
                    />
                </S.PatientInfoBlock>
            </S.PatientInfoRow>
            <ExercisePlanProgress expandedState={exerciseExpandedState} />
            <CareManagementAndCourseRow expandedState={careManagementExpandedState} />
        </>
    );
};

export default PatientSummary;
