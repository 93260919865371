import React from 'react';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const LinkToHealthie = ({ healthieId }: { healthieId: string }) => {
    const healthieUrl = process.env.REACT_APP_HEALTHIE_URL;
    if (!healthieUrl) {
        return null;
    }
    return (
        <S.LinkRow href={`${healthieUrl}/users/${healthieId}`} target="_blank" rel="noreferrer">
            <S.LinkImg src="/Healthie.svg" alt="Healthie" />
            <S.LinkArrow src="/LinkArrow.svg" alt="Link Arrow" />
        </S.LinkRow>
    );
};

export default LinkToHealthie;
