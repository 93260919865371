import React from 'react';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

type Props = {
    nextAppointmentDate: string;
    lastAppointmentDate: string;
    missedAppointments: number;
}

const AppointmentDates = ({ nextAppointmentDate, lastAppointmentDate, missedAppointments }: Props) => {
    return (
        <div>
            <p>
                <FontAwesomeIcon icon={faCalendar as IconProp} size="xs" />
                {' '}Next: {nextAppointmentDate}
            </p>
            <p>
                <FontAwesomeIcon icon={faCalendarCheck as IconProp} size="xs" />
                {' '}Last: {lastAppointmentDate}
            </p>
            <p>
                Missed: {missedAppointments}
            </p>
        </div>
    );
};

export default AppointmentDates;
