import React from 'react';
import { IPatient, PatientStatus, PCMStatusEnum, vPRStatusEnum } from '../../../../types/models';
import * as S from './index.styles';
import { PatientStatusTag } from '../../../../components/common/commonStyled';
import PatientDataTableCells from '../../../PatientsList/components/PatientDataTableCells';

type Props = {
    patient: IPatient;
    children?: React.ReactNode;
}

const PatientPageHeader = ({ children, patient }: Props) => (
    <S.PageHeader>
        <S.PatientInfo>
            <S.HeaderRow>
                <S.HeaderName>{patient.healthieFirstName} {`(${patient.firstName})`} {patient.healthieLastName}</S.HeaderName>
                {patient.vPRStatus === vPRStatusEnum.VPR && <PatientStatusTag status={PatientStatus.VPR} style={{ margin: '7px 0px', padding: '5px 0px' }}>VPR</PatientStatusTag>}
                {patient.vPRStatus === vPRStatusEnum.MAINTENANCE && <PatientStatusTag status={PatientStatus.MNT} style={{ margin: '7px 0px', padding: '5px 0px' }}>MNT</PatientStatusTag>}
                {patient.PCMStatus === PCMStatusEnum.PCM && <PatientStatusTag status={PatientStatus.PCM} style={{ margin: '7px 0px', padding: '5px 0px' }}>PCM</PatientStatusTag>}
                {patient.isPaused && <PatientStatusTag status={PatientStatus.PAUSED} style={{ margin: '7px 0px', padding: '5px 0px' }}>Paused</PatientStatusTag>}
            </S.HeaderRow>
            <S.FullLegalName>{patient.fullLegalName}</S.FullLegalName>
            <S.ContactInfo>
                <S.ContactItem>{patient.email}</S.ContactItem>
                <S.ContactItem>{patient.phoneNumber}</S.ContactItem>
            </S.ContactInfo>
        </S.PatientInfo>
        <S.Actions>
            {children}
        </S.Actions>
    </S.PageHeader>
);

export default PatientPageHeader;
