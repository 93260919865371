import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

interface ColumnProps {
    maxWidth?: string;
}

export const PatientInfoRow = styled.div<{border?: string, gap?: string}>`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    padding-bottom: 50px;
    width: 100%;
    border-bottom: ${props => props.border || `1px solid ${props.theme.shades.lightGrey}`};
    gap: ${props => props.gap ? props.gap : '0'};
`;

export const PatientInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 min(10px, 2%) min(20px, 2%) 0;
    min-width: max(150px, 24%);

    span {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 5px;
    }

    p {
        margin: 0;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
`;

export const HeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Header = styled.h3`
`;

export const PatientInfoColumn = styled.div<ColumnProps>`
    display: flex;
    flex-direction: column;
    max-width: ${props => props.maxWidth || '100%'};
`;

export const ConditionInfo = styled.div`
    display: grid;
    width: 80%;
`;

export const ConditionInfoDetails = styled.div`
    display: flex;
    gap: 70px;
`;

export const PhysicalInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
    width: 80%;
`;

export const PhysicalInfoDetails = styled.div`
    display: flex;
    gap: 70px;
`;

export const ExternalLinks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const LinkImg = styled.img`
    width: 100px;
    height: 20px;
    object-fit: contain;
    object-position: left;
`;

export const LinkRow = styled.a`
    display: flex;
    gap: 3px;
    align-items: center;
`;

export const LinkArrow = styled.img`
    width: 10px;
    height: 10px;
    object-fit: contain;
`;

export const ManageButton = styled(Buttons.Button)`
    margin: 0;
    width: 110px;
    height: 50px;
    text-align: right;
`;
