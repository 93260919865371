import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IPatientListUser, IUser, PatientStatus, PCMStatusEnum, ProgramStatus, vPRStatusEnum } from '../../../../types/models';
import { PatientStatusTag } from '../../../../components/common/commonStyled';
import * as S from '../index.styles';
import LinkToHealthie from '../../../../components/LinkToHealthie';

const StatusCell = ({ PCMStatus, vPRStatus, isPaused }: IPatientListUser) => (
    <div>
        {vPRStatus === vPRStatusEnum.VPR && <PatientStatusTag status={PatientStatus.VPR} style={{ margin: '7px 0px', padding: '5px 0px' }}>VPR</PatientStatusTag>}
        {vPRStatus === vPRStatusEnum.MAINTENANCE && <PatientStatusTag status={PatientStatus.MNT} style={{ margin: '7px 0px', padding: '5px 0px' }}>MNT</PatientStatusTag>}
        {PCMStatus === PCMStatusEnum.PCM && <PatientStatusTag status={PatientStatus.PCM} style={{ margin: '7px 0px', padding: '5px 0px' }}>PCM</PatientStatusTag>}
        {isPaused && <PatientStatusTag status={PatientStatus.PAUSED} style={{ margin: '7px 0px', padding: '5px 0px' }}>Paused</PatientStatusTag>}
    </div>
);

const PatientCell = ({ firstName, lastName, primaryDiagnosis }: IPatientListUser) => (
    <div>
        <S.PatientName>{firstName} {lastName}</S.PatientName>
        <S.PatientPrimaryDiagnosis>{primaryDiagnosis}</S.PatientPrimaryDiagnosis>
    </div>
);

const ViewMoreCell = ({ healthieId, lastViewedAt }: IPatientListUser) => (
    <S.ViewMore>
        <p>{lastViewedAt}</p>
        <LinkToHealthie healthieId={healthieId} />
    </S.ViewMore>
);

export default {
    StatusCell,
    PatientCell,
    ViewMoreCell
};
