import { format } from 'date-fns';

export const monthDateYear = (date: Date) => {
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
};

// Date object without timezone information
export class NaiveDate extends Date {
    constructor(date: string) {
        const year = parseInt(date.substring(0, 4), 10);
        const month = parseInt(date.substring(5, 7), 10) - 1;
        const day = parseInt(date.substring(8, 11), 10);
        super(year, month, day);
    }

    setTimeToMidnight() {
        this.setUTCHours(0, 0, 0, 0);
        return this;
    }
}

// converts date object to format that can be used in input type="date"
// e.g. 2021-01-01
export const formatDateForInput = (date: Date) => {
    // account for timezone differences
    date.setDate(date.getUTCDate());

    return `${format(date, 'yyyy-MM-dd')}`;
};

// converts date object to format that can be used in input type="datetime-local"
// e.g. 2021-01-01T00:00
export const formatDateTimeForInput = (date: Date) => {
    // account for timezone differences
    date.setDate(date.getUTCDate());

    return `${format(date, 'yyyy-MM-dd')}T00:00`;
};

export const formatDateForPatientPage = (date: Date) => {
    // TODO: remove when I have actual data
    if (!date) {
        return '';
    }

    return format(date, 'MMM do yyyy');
};
