import React, { useState } from 'react';
import * as S from './index.styles';
import { Modal, Buttons } from '@apps/common-ui';

type Props = {
    onClose: () => void;
    handleSelect: (boolean) => void;
};

const PaymentAccessModal = ({ onClose, handleSelect }: Props) => {
    return (
        <Modal showModal dismissModal={onClose}>
            <div>
                <h3>Should this patient have access to paying bills within Healthies Payment Portal</h3>
                <p>As a reminder this requires a patient to create and use a healthie account in order to do this</p>
                {/* TODO: Convert to Fadi's Radio when available */}
                <div>
                    <Buttons.Button
                      onClick={() => handleSelect(true)}
                    >
                        Yes
                    </Buttons.Button>
                    <Buttons.Button
                      onClick={() => handleSelect(false)}
                    >
                        No
                    </Buttons.Button>
                </div>
                <div>
                    <Buttons.Button onClick={onClose}>
                        Confirm
                    </Buttons.Button>
                </div>
            </div>
        </Modal>
    );
};

export default PaymentAccessModal;
