import React from 'react';
import * as S from '../index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { PatientStatusTag } from '../../../../components/common/commonStyled';
import { IPatientError, PatientStatus, PCMStatusEnum, vPRStatusEnum } from '../../../../types/models';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import LinkToHealthie from '../../../../components/LinkToHealthie';

const StatusCell = ({ PCMStatus, vPRStatus, isPaused }: IPatientError) => (
    <div>
        {vPRStatus === vPRStatusEnum.VPR && <PatientStatusTag status={PatientStatus.VPR} style={{ margin: '7px 0px', padding: '5px 0px' }}>VPR</PatientStatusTag>}
        {vPRStatus === vPRStatusEnum.MAINTENANCE && <PatientStatusTag status={PatientStatus.MNT} style={{ margin: '7px 0px', padding: '5px 0px' }}>MNT</PatientStatusTag>}
        {PCMStatus === PCMStatusEnum.PCM && <PatientStatusTag status={PatientStatus.PCM} style={{ margin: '7px 0px', padding: '5px 0px' }}>PCM</PatientStatusTag>}
        {isPaused && <PatientStatusTag status={PatientStatus.PAUSED} style={{ margin: '7px 0px', padding: '5px 0px' }}>Paused</PatientStatusTag>}
    </div>
);

const PatientCell = ({ firstName, lastName, email }: IPatientError) => (
    <div>
        <S.PatientName>{firstName} {lastName}</S.PatientName>
        <S.PatientPrimaryDiagnosis>{email}</S.PatientPrimaryDiagnosis>
    </div>
);

const ViewMoreCell = ({ userId, userHealthieId }: IPatientError) => (
    <S.ViewMore>
        <LinkToHealthie healthieId={userHealthieId} />
        <S.PatientDetailsButton buttonType="tertiary" to={`/patients/${userId}`}>
            Patient Details
        </S.PatientDetailsButton>
    </S.ViewMore>
);

export default {
    StatusCell,
    PatientCell,
    ViewMoreCell
};
